@import "./../styles/variables.scss";

.header {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  border-bottom: 2px solid $secondary;
  height: $header-height;
  z-index: 1;
  .container {
    width: $body-width;
    margin-left: auto;
    margin-right: auto;
    .brands {
      align-items: center;
    }
  }
  .nav-item {
    margin-right: 20px;
    a {
      display: inline-block;
      text-decoration: none;
      color: $secondary;
    }
    .active {
      // background-color: gray;
      // color: $primary !important;
      font-weight: bold;
      text-decoration: underline;
      opacity: 0.8;
    }
    &:has(> .active) {
      // background-color: gray;
      // color: $primary !important;
      font-weight: bold;
      text-decoration: underline;
      opacity: 0.8;
    }
  }
}
