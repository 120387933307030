@use "./../styles/variables.scss" as variables;

@function viewportHeight($height) {
    $size: $height - variables.$header-height + 1;
    @return $size;
}

.elev-card {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    width: variables.$body-width;
    min-height: variables.$card-height;
    margin-bottom: 20px;
    &.noMargin {
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

.button {
    text-align: center;
}

.leftAlign {
    text-align: left;
}

.rightAlign {
    text-align: right;
}

.centerAlign {
    text-align: center;
}

.oneColumn {
    text-align: center;
    flex-direction: column;
    &:not(.no-padding) {
        padding: 20px 50px;
    }
    .title {
    }
    .content {
        margin: 10px;
    }
}

.twoColumn {
    .column {
        text-align: center;
        &.gridColumn {
            display: grid;
            grid-template-columns: repeat(6, 1fr); /* Creates 9 columns */
            grid-template-rows: repeat(6, 1fr); /* Creates 9 rows */
            // width: 400px; /* Set the width of the grid container */
            // height: 400px; /* Set the height of the grid container */

        }
        &.imgColumn {
            display: flex;
            justify-content: center;
            align-items: center
        }
        &.textColumn {
            padding: 20px;
            margin-top: auto;
            margin-bottom: auto;
            .title {
                color: variables.$secondary;
            }
            .text {
            }
        }
        .helper {
            display: inline-block;
            height: 100%;
            vertical-align: middle;
        }
        img {
            max-width: 100%;
            max-height: 100%;
            text-align: center;;
            &.smallImg {
                max-width: 10%;
                margin: 15px;
            }
        }
        .imageRow {
            display: flex;
            align-items: center;
        }
    }
}

.threeColumn {
    .column {
        text-align: center;
        padding: 20px;
        &.textColumn {
            margin-top: auto;
            margin-bottom: auto;
            .title {
                color: variables.$secondary;
            }
            .text {
            }
        }
        width: 34%;
        .helper {
            display: inline-block;
            height: 100%;
            vertical-align: middle;
        }
        img {
            max-width: 100%;
            &.smallImg {
                max-width: 10%;
                margin: 15px;
            }
        }
    }
}

.background-primary {
    background-color: variables.$primary;
}

@media screen and (min-aspect-ratio: variables.$aspect-ratio) {
    .hide-desktop {
        display: none !important;
    }

    .twoColumn {
        .column {
            &:not(.bigCol, .smallCol, .exBigCol, .exSmallCol) {
                width: 50%;
            }
            &.bigCol {
                width: 70%;
            }
            &.smallCol {
                width: 30%;
            }
            &.exBigCol {
                width: 80%;
            }
            &.exSmallCol {
                width: 20%;
            }
        }
    }

    .threeColumn {
        .column {
            &:not(.bigCol, .smallCol, .exBigCol, .exSmallCol) {
                width: 33%;
            }
            &.bigCol {
                width: 50%;
            }
            &.smallCol {
                width: 25%;
            }
            &.exBigCol {
                width: 60%;
            }
            &.exSmallCol {
                width: 20%;
            }
        }
    
    }
}

@media screen and (max-aspect-ratio: variables.$aspect-ratio) {
    .hide-mobile {
        display: none !important;
    }
    
    .m-primary-background {
        background-color: variables.$primary;
    }
    
    .doubleImage {
        display: block;
        .imageDiv {
            &.FirstImageDiv {
                width: 100%;
            }
            &.SecondImageDiv {
                width: 100%;
                .image {
                    max-width: 20%;
                    margin-left: auto;
                    margin-right: auto;
                    display: flex;
                }
            }
        }
    }

    .imageWithText {
        display: block;
        margin-bottom: 20px;
        .imageDiv {
            // display: block;
            width: 100%;
            .image {
                max-width: 100%;
            }
            &.show {
                display: none;
            }
            &.left {
                display: block;
            }
        }
        .textDiv {
            width: 100%;
            padding: 20px;
            text-align: center;
            // color: variables.$primary;
            .title {
                color: variables.$secondary;
            }
            .text {
            }
        }
    }

    .twoColumn {
        display: flex;
        flex-direction: column;
        .column.m-padding {
            padding: 30px;
        }
        .first,
        .leftColumn:not(.second) {
            order: 1;
        }
        .second,
        .rightColumn:not(.first) {
            order: 2;
        }
    }

    .threeColumn {
        display: flex;
        flex-direction: column;
        .first .leftColumn:not(.second, .third) {
            order: 1;
        }
        .second .middleColumn:not(.first, .third) {
            order: 2;
        }
        .third,
        .rightColumn:not(.first, .second) {
            order: 3;
        }
        .column {
            &:not(.m-padding) {
                padding: 0;
            }
            &.m-padding {
                padding: 30px;
            }
            width: 100%;
            img {
                max-width: 75%;
            }
        }
    }
}
