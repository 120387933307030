@use "./../styles/variables.scss" as variables;

.elevert-carousel {
    position: relative;
}

.elevert-carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.elevert-carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transition: transform 0.6s ease-in-out;
    img {
        border: 1px solid variables.$secondary;
        max-width: 100%;
    }
}

.big {
    background-color: #000;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    img {
        color: #424242;
        -webkit-transition: all 0.3s ease-in;
        -moz-transition: all 0.3s ease-in;
        -ms-transition: all 0.3s ease-in;
        -o-transition: all 0.3s ease-in;
        transition: all 0.3s ease-in;
        opacity: 1;

        width: 50%;
    }
}

.active.elevert-carousel-item-start,
.elevert-carousel-item-prev:not(.elevert-carousel-item-end) {
    transform: translateX(-100%);
}

.active.elevert-carousel-item-end,
.elevert-carousel-item-next:not(.elevert-carousel-item-start) {
    transform: translateX(100%);
}

.elevert-carousel-item-next,
.elevert-carousel-item-prev,
.elevert-carousel-item.active {
    display: block;
}

.elevert-w-100 {
    width: 100% !important;
}

.elevert-carousel-next,
.elevert-carousel-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #000;
    text-align: center;
    background: 0 0;
    border: 0;

    opacity: 0.5;
    transition: opacity 0.15s ease;
    font-size: larger;
    font-weight: bold;
    div {
        border: 1px solid variables.$secondary;
    }
}

.elevert-carousel-next:focus,
.elevert-carousel-prev:focus,
.elevert-carousel-next:hover,
.elevert-carousel-prev:hover {
    color: #000;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
}

.elevert-carousel-prev {
    left: 0;
}

.elevert-carousel-next {
    right: 0;
}
