@import "./variables.scss";

body,
#root,
.App {
  background-color: $primary !important;
  // color: $secondary !important;
}

.emptyHeader {
  height: $header-height;
}

.bg-primary {
  background-color: $bg-primary !important;
  color: $secondary !important;
}

.navbar-brand,
.nav-link {
  color: $secondary !important;
}

@media screen and (max-aspect-ratio: $aspect-ratio) {
  $header-height: 5vh;
  .header {
    height: $header-height;
  }
  .emptyHeader {
    height: $header-height;
  }
}

// set changes
// @import "bootstrap";
