.cookieConsentBanner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  color: #fff;
  padding: 10px;
  text-align: center;
  z-index: 1;
}

.cookieConsentBannerText {
  margin: 0;
}

.cookieConsentBannerButton {
  background-color: #35b6f6;
  border: none;
  padding: 5px 10px;
  margin: 0 10px;
  cursor: pointer;
  border-radius: 5px;
  &.accept {
    background-color: #B6F635;
  }

  &.decline {
    background-color: #35b6f6;
  }

  &.info {
    background-color: lightslategray;
  }
}
