.elevert-modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    
    .elevert-modal-background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: -200vh;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1000;
    }
    .elevert-modal-content {
        position: relative;
        left: 20vw;
        right: 20vw;
        width: 800px;
        z-index: 1001;
        opacity: 1;

        img {
           width: 100%;
        }
    }
}