
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/utilities/api";


$primary: #f6d635;
$secondary: #f67535;

// merge with existing $theme-colors map
$theme-colors: map-merge(
  $theme-colors,
  (
    "primary": $primary,
    "secondary": $secondary
  )
);

$bg-primary: $primary;
$bg-secondary: $secondary;

$header-height: 10vh;
$aspect-ratio: "100/68";
$body-width: 70vw;
$card-height: 20vh;

@media screen and (max-aspect-ratio: $aspect-ratio) {
    $header-height: 5vh;
  }