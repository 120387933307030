@import "./../styles/variables.scss";

.footer {
    
    background-color: $secondary !important;
    height: 20vh;
    width: 100%;
    margin-top: 50px;
    .footerContent {
        text-align: center;
        padding-top: 25px;
    }
}

.footer.isDevelopment {
    background-color: green !important;
    .footerContent {
        background-color: green;
    }
}

@media screen and (max-aspect-ratio: $aspect-ratio) {
    .footer {
        height: 10vh;
    }
}
