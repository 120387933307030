@import "../styles/variables.scss";

.button {
    background-color: lightblue;
    border-radius: 5px;
    width: fit-content;
    padding: 5px;
    cursor: pointer;
    &:hover {
        background-color: lightskyblue;
    }
    &[disabled] {
        background-color: lightgray;
        cursor: default;
    }
}